import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default function () {
    const Store = new Vuex.Store({
        state: {
            user: {},
            token: "",
        },
        modules: {
        },
        getters: {
            user(state) {
                let json = localStorage.getItem("user");
                if (json != "undefined") {
                    var u = JSON.parse(json);
                    if (u) {
                        state.user = u;
                        return state.user;
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            token(state) {
                return state.token
            }
        },
        mutations: {
            setUser(state, user) {
                localStorage.setItem("user", JSON.stringify(user));
                state.user = user;
            },
            setToken(state, token) {
                localStorage.setItem("token", token);
                state.token = token;
            },
            logout(state) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                state.user = {};
                state.token = null
            },

        }
    })
    return Store
}