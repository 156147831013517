import Home from "./pages/Home.vue";
import NeedLogin from "./pages/err/NeedLogin.vue";

const routers = [
  {
    path: "",
    name: "Home",
    component: Home,
    meta: {
      title: "应用中心",
      requireAuth: false,
      keepAlive: true,
    },
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("./pages/index/Index.vue"),
        meta: {
          title: "首页",
          requireAuth: false,
          keepAlive: true,
        },
      },
      {
        path: "/checkin",
        name: "checkin",
        component: () => import("./pages/checkin/Index.vue"),
        meta: {
          title: "报到",
          requireAuth: false,
          keepAlive: false,
        },
      },
      {
        path: "/me",
        name: "me",
        component: () => import("./pages/me/Index.vue"),
        meta: {
          title: "我的",
          requireAuth: false,
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/news/page",
    name: "newsPage",
    component: () => import("./pages/index/NewsPage.vue"),
    meta: {
      title: "列表",
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/news/detail",
    name: "newsDetail",
    component: () => import("./pages/index/NewsDetail.vue"),
    meta: {
      title: "详情",
      requireAuth: false,
    },
  },
  {
    path: "/index/imgs",
    name: "imgs",
    component: () => import("./pages/index/Imgs.vue"),
    meta: {
      title: "校园掠影",
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/index/contact",
    name: "contact",
    component: () => import("./pages/index/Contact.vue"),
    meta: {
      title: "联系我们",
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/me/profile",
    name: "profile",
    component: () => import("./pages/me/Profile.vue"),
    meta: {
      title: "个人资料",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/fee",
    name: "fee",
    component: () => import("./pages/checkin/Fee.vue"),
    meta: {
      title: "我的学费",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/greenFee",
    name: "greenFee",
    component: () => import("./pages/checkin/GreenFee.vue"),
    meta: {
      title: "绿色缴费通道",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/pay",
    name: "pay",
    component: () => import("./pages/checkin/Pay.vue"),
    meta: {
      title: "在线缴费",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/hostel",
    name: "hostel",
    component: () => import("./pages/checkin/Hostel.vue"),
    meta: {
      title: "我的宿舍",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/hostelFeeReq",
    name: "hostelFeeReq",
    component: () => import("./pages/checkin/HostelFeeReq.vue"),
    meta: {
      title: "缓交住宿费申请",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/visit",
    name: "visit",
    component: () => import("./pages/checkin/Visit.vue"),
    meta: {
      title: "来校登记",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/delay",
    name: "delay",
    component: () => import("./pages/checkin/Delay.vue"),
    meta: {
      title: "延迟报到",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/fullday",
    name: "fullday",
    component: () => import("./pages/checkin/Fullday.vue"),
    meta: {
      title: "申请在校住宿就读",
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/checkin/transfer",
    name: "transfer",
    component: () => import("./pages/checkin/Transfer.vue"),
    meta: {
      title: "转专业申请",
      requireAuth: true,
      keepAlive: true,
    },
  },
  {
    path: "/checkin/adviceNote",
    name: "adviceNote",
    component: () => import("./pages/checkin/AdviceNote.vue"),
    meta: {
      title: "录取通知书",
      requireAuth: true,
      keepAlive: true,
    },
  },
  {
    path: "/checkin/step",
    name: "step",
    component: () => import("./pages/checkin/Step.vue"),
    meta: {
      title: "报到流程",
      requireAuth: true,
      keepAlive: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/auth/Login.vue"),
    meta: {
      title: "登录",
      requireAuth: false,
    },
  },
  {
    path: "/login/userAgreement",
    name: "userAgreement",
    component: () => import("./pages/auth/UserAgreement.vue"),
    meta: {
      title: "用户协议",
      requireAuth: false,
    },
  },
  {
    path: "/login/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("./pages/auth/PrivacyPolicy.vue"),
    meta: {
      title: "隐私政策",
      requireAuth: false,
    },
  },
  {
    path: "/err",
    name: "Error",
    component: NeedLogin,
    meta: {
      title: "发生错误",
      requireAuth: false,
    },
  },
];

export default routers;
