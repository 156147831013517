import Vue from 'vue'
import App from './App.vue'
import './assets/style.css'

Vue.config.productionTip = false

// 路由
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import routers from './routers'
const router = new VueRouter({
  mode: 'history',
  routes: routers
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  let meta1 = document.createElement('meta');
  meta1.setAttribute('name', 'viewport');
  meta1.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover');
  document.head.appendChild(meta1);

  let meta2 = document.createElement('meta');
  meta2.setAttribute('name', 'apple-mobile-web-app-capable');
  meta2.setAttribute('content', 'yes');
  document.head.appendChild(meta2)

  const token = localStorage.getItem('token')
  if (to.matched.some(res => res.meta.requireAuth)) {
    if (token) {
      next()
    } else {
      next('/login');
    }
  } else {
    next()
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 路由END

// vant
import Vant from 'vant'

import 'vant/lib/index.css'
Vue.use(Vant);
import { Lazyload } from 'vant';
Vue.use(Lazyload);
import store from './store/'

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
}).$mount('#app')
