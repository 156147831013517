<template>
  <div>
    <router-view />
    <van-tabbar
      route
      v-model="active"
      active-color="#ee0a24"
      inactive-color="#212121"
    >
      <van-tabbar-item name="home" replace to="/index" icon="home-o"
        >首页</van-tabbar-item
      >
      <van-tabbar-item name="checkin" replace to="/checkin">
        <span>新生报到</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="me" replace to="/me" icon="user-o"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: require("@/assets/tab-bar/logoHL.png"),
        inactive: require("@/assets/tab-bar/logo.png"),
      },
    };
  },
  watch: {},
  created() {},
};
</script>

<style scoped>
</style>